html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #1976d2;
  --mdc-linear-progress-track-color: rgba(25, 118, 210, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(25, 118, 210, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(25, 118, 210, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(25, 118, 210, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(25, 118, 210, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(25, 118, 210, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #d32f2f;
  --mdc-linear-progress-track-color: rgba(211, 47, 47, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(211, 47, 47, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(211, 47, 47, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(211, 47, 47, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(211, 47, 47, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(211, 47, 47, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
html {
  --mat-sidenav-container-shape: 0;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}

html {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-disabled-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-disabled-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-disabled-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-disabled-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #1976d2;
  --mat-text-button-state-layer-color: #1976d2;
  --mat-text-button-ripple-color: rgba(25, 118, 210, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #d32f2f;
  --mat-text-button-state-layer-color: #d32f2f;
  --mat-text-button-ripple-color: rgba(211, 47, 47, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #1976d2;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #d32f2f;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #1976d2;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #d32f2f;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #1976d2;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #1976d2;
  --mat-outlined-button-ripple-color: rgba(25, 118, 210, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #d32f2f;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #d32f2f;
  --mat-outlined-button-ripple-color: rgba(211, 47, 47, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #1976d2;
}
.mat-icon.mat-accent {
  --mat-icon-color: #d32f2f;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #1976d2;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #d32f2f;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #1976d2;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #1976d2;
  --mat-tab-header-active-ripple-color: #1976d2;
  --mat-tab-header-inactive-ripple-color: #1976d2;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1976d2;
  --mat-tab-header-active-hover-label-text-color: #1976d2;
  --mat-tab-header-active-focus-indicator-color: #1976d2;
  --mat-tab-header-active-hover-indicator-color: #1976d2;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #d32f2f;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #d32f2f;
  --mat-tab-header-active-ripple-color: #d32f2f;
  --mat-tab-header-inactive-ripple-color: #d32f2f;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #d32f2f;
  --mat-tab-header-active-hover-label-text-color: #d32f2f;
  --mat-tab-header-active-focus-indicator-color: #d32f2f;
  --mat-tab-header-active-hover-indicator-color: #d32f2f;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #1976d2;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #d32f2f;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #d32f2f;
}

body {
  color: #444444;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
}

h2 {
  border-top: 1px solid #DBDBDB;
}

h6 {
  color: #6E6E6E;
}

p,
ol,
ul,
li,
input,
a {
  color: #444444;
}

.app-toolbar a {
  color: #FFFFFF;
}

code {
  color: #444444;
}

.sidenav-content a {
  color: #1976D2;
}

.error-text {
  color: #DD0031;
}

footer {
  background-color: #1976d2;
}
footer aio-footer > * {
  color: #FFFFFF;
}
footer aio-footer a {
  color: #FFFFFF;
}
footer aio-footer h3 {
  color: #FFFFFF;
}

html,
body,
.content {
  background: #FFFFFF;
}

.background-sky {
  background: linear-gradient(145deg, #0d47a1, #42a5f5);
  color: rgba(0, 0, 0, 0.87);
}

section#intro {
  color: #FFFFFF;
}

.announcement-bar {
  background-color: #FFFFFF;
}
.announcement-bar .button {
  color: #FFFFFF;
  background-color: #1976D2;
}
.announcement-bar .button:hover {
  color: rgba(255, 255, 255, 0.7);
}

.home-row .card {
  background-color: #FFFFFF;
}
.home-row .card .card-text-container p {
  color: #444444;
}
.home-row .card:hover h2 {
  color: #1976D2;
}

.button.hero-cta {
  background-color: #FFFFFF;
}

.cta-bar .hero-cta {
  color: #1976D2;
}

.text-headline {
  color: #1976D2;
}

.marketing-banner {
  background-color: #1e88e5;
}
.marketing-banner .banner-headline {
  color: #FFFFFF;
}

.nf-response h1 {
  color: #1976D2;
}

.nf-icon.material-icons {
  color: #1976D2;
}

mat-sidenav-container.sidenav-container {
  background-color: #FFFFFF;
}
mat-sidenav-container.sidenav-container mat-sidenav.sidenav {
  background-color: #F2F2F2;
  border-right: 1px solid #DBDBDB;
}
mat-sidenav-container.sidenav-container mat-sidenav.sidenav .doc-version {
  border-top: 1px solid #DBDBDB;
}

aio-nav-menu aio-nav-item .vertical-menu-item {
  color: #444444;
}
aio-nav-menu aio-nav-item .vertical-menu-item:hover {
  background-color: #DBDBDB;
  color: #1976D2;
  text-shadow: 0 0 5px #FFFFFF;
}
aio-nav-menu aio-nav-item .vertical-menu-item:hover.selected {
  color: #1669bb;
}
aio-nav-menu aio-nav-item .vertical-menu-item.selected {
  color: #1669bb;
}

.page-home mat-toolbar.app-toolbar {
  background: #1976D2;
}
mat-toolbar.app-toolbar mat-icon {
  color: #FFFFFF;
}
mat-toolbar.app-toolbar .hamburger:hover {
  color: #FAFAFA;
}
mat-toolbar.app-toolbar .hamburger .mat-icon {
  color: #FFFFFF;
}
mat-toolbar.app-toolbar .nav-link.home:focus {
  outline: 1px solid rgba(255, 255, 255, 0.8);
}
mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link .nav-link-inner:hover {
  background: rgba(255, 255, 255, 0.15);
}
mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link:focus .nav-link-inner {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0.8);
}
mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link:active .nav-link-inner {
  background: rgba(255, 255, 255, 0.15);
}
mat-toolbar.app-toolbar aio-top-menu ul li.selected a.nav-link .nav-link-inner {
  background: rgba(255, 255, 255, 0.15);
}
mat-toolbar.app-toolbar aio-search-box.search-container input {
  color: #444444;
  background-color: #FFFFFF;
}
mat-toolbar.app-toolbar aio-search-box.search-container input:-ms-input-placeholder {
  color: #6E6E6E;
}
mat-toolbar.app-toolbar aio-search-box.search-container input::-ms-input-placeholder {
  color: #6E6E6E;
}
mat-toolbar.app-toolbar aio-search-box.search-container input::-webkit-input-placeholder {
  color: #6E6E6E;
}
mat-toolbar.app-toolbar aio-search-box.search-container input::placeholder {
  color: #6E6E6E;
}
mat-toolbar.app-toolbar aio-search-box.search-container .search-box-shortcut {
  border-color: #6E6E6E;
  color: #6E6E6E;
}
@media screen and (min-width: 351px) {
  mat-toolbar.app-toolbar aio-theme-toggle {
    border-right: 1px solid #FFFFFF;
  }
}

.alert {
  color: #444444;
}
.alert.is-critical {
  border-left: 8px solid #DD0031;
  background-color: rgba(221, 0, 49, 0.05);
}
.alert.is-critical h1,
.alert.is-critical h2,
.alert.is-critical h3,
.alert.is-critical h4,
.alert.is-critical h5,
.alert.is-critical h6 {
  color: #DD0031;
}
.alert.is-important {
  border-left: 8px solid #FF9800;
  background-color: rgba(255, 152, 0, 0.05);
}
.alert.is-important h1,
.alert.is-important h2,
.alert.is-important h3,
.alert.is-important h4,
.alert.is-important h5,
.alert.is-important h6 {
  color: #FF9800;
}
.alert.is-helpful {
  border-left: 8px solid #1976D2;
  background-color: rgba(25, 118, 210, 0.05);
}
.alert.is-helpful h1,
.alert.is-helpful h2,
.alert.is-helpful h3,
.alert.is-helpful h4,
.alert.is-helpful h5,
.alert.is-helpful h6 {
  color: #1976D2;
}
.alert.archive-warning {
  background-color: #C3002F;
}
.alert.archive-warning * {
  color: #FFFFFF;
}
.alert.archive-warning a {
  color: #FFFFFF;
}

aio-api-list .api-filter .form-search input {
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  color: #444444;
}
aio-api-list .api-filter .form-search input:-ms-input-placeholder {
  color: #6E6E6E;
}
aio-api-list .api-filter .form-search input::-ms-input-placeholder {
  color: #6E6E6E;
}
aio-api-list .api-filter .form-search input::-webkit-input-placeholder {
  color: #6E6E6E;
}
aio-api-list .api-filter .form-search input::placeholder {
  color: #6E6E6E;
}
aio-api-list .api-filter .form-search input:focus {
  border: 1px solid #42A5F5;
  box-shadow: 0 2px 2px rgba(66, 165, 245, 0.24), 0 0 2px rgba(66, 165, 245, 0.12);
}
aio-api-list .api-filter .form-search .material-icons {
  color: #546E7A;
}
aio-api-list .api-list-container a {
  color: #1976D2;
}
aio-api-list .api-list-container .api-list li a {
  color: #546E7A;
}
aio-api-list .api-list-container .api-list li a:hover {
  background: #ECEFF1;
  color: #2196F3;
}

.symbol {
  box-shadow: 0 1px 2px rgba(10, 16, 20, 0.24);
  color: #FFFFFF;
}
.symbol.all {
  background: #FFFFFF;
}
.symbol.all:before {
  content: " ";
}
.symbol.class {
  background: #2196F3;
}
.symbol.class:before {
  content: "C";
}
.symbol.const {
  background: #6E6E6E;
}
.symbol.const:before {
  content: "K";
}
.symbol.decorator {
  background: #1565C0;
}
.symbol.decorator:before {
  content: "@";
}
.symbol.directive {
  background: #D81B60;
}
.symbol.directive:before {
  content: "D";
}
.symbol.enum {
  background: #FFA000;
}
.symbol.enum:before {
  content: "E";
}
.symbol.function {
  background: #4CAF50;
}
.symbol.function:before {
  content: "F";
}
.symbol.interface {
  background: #009688;
}
.symbol.interface:before {
  content: "I";
}
.symbol.let {
  background: #6E6E6E;
}
.symbol.let:before {
  content: "K";
}
.symbol.ngmodule {
  background: #940;
}
.symbol.ngmodule:before {
  content: "M";
}
.symbol.package {
  background: #8E24AA;
}
.symbol.package:before {
  content: "Pk";
}
.symbol.pipe {
  background: #546E7A;
}
.symbol.pipe:before {
  content: "P";
}
.symbol.element {
  background: #AA3000;
}
.symbol.element:before {
  content: "El";
}
.symbol.block {
  background: #2E7D32;
}
.symbol.block:before {
  content: "B";
}
.symbol.type-alias {
  background: #7CB342;
}
.symbol.type-alias:before {
  content: "T";
}
.symbol.var {
  background: #6E6E6E;
}
.symbol.var:before {
  content: "K";
}

a.button.mat-button.button-secondary,
.button.button-secondary {
  background: #6E6E6E;
  color: rgba(255, 255, 255, 0.87);
}
a.button.mat-button.button-plain,
.button.button-plain {
  background: #FFFFFF;
  color: rgba(68, 68, 68, 0.87);
}
a.button.mat-button.button-subtle,
.button.button-subtle {
  background: #6E6E6E;
  color: #e1e1e1;
}
a.button.mat-button.button-subtle:hover,
.button.button-subtle:hover {
  color: rgba(255, 255, 255, 0.7);
}
a.button.mat-button.button-blue,
.button.button-blue {
  background: #1976D2;
  color: rgba(255, 255, 255, 0.87);
}
a.button.mat-button.button-blue:hover,
.button.button-blue:hover {
  color: rgba(255, 255, 255, 0.7);
}
a.button.mat-button.button-banner,
.button.button-banner {
  background: #444444;
  color: rgba(255, 255, 255, 0.87);
}

.cta-bar .button:hover {
  color: #FAFAFA;
}

.group-buttons button.button.mat-button.filter-button {
  background-color: rgba(25, 118, 210, 0.2);
}
.group-buttons button.button.mat-button.filter-button.selected {
  background-color: #1976D2;
  color: #FFFFFF;
}
.group-buttons button.button.mat-button.filter-button:focus, .group-buttons button.button.mat-button.filter-button:hover {
  background-color: rgba(25, 118, 210, 0.7);
  color: #FFFFFF;
}

.callout header {
  color: #FFFFFF;
}
.callout.is-critical {
  border-color: #DD0031;
  background: rgba(221, 0, 49, 0.05);
}
.callout.is-critical header {
  background: #DD0031;
}
.callout.is-important {
  border-color: #FF9800;
  background: rgba(255, 152, 0, 0.05);
}
.callout.is-important header {
  background: #FFA000;
}
.callout.is-helpful {
  border-color: #1976D2;
  background: rgba(25, 118, 210, 0.05);
}
.callout.is-helpful header {
  background: #1976D2;
}

.card-container .docs-card {
  background-color: #FFFFFF;
}
.card-container .docs-card section {
  color: #1976D2;
}
.card-container .docs-card p {
  color: #444444;
}
.card-container .docs-card .card-footer {
  color: #1669bb;
  background-color: rgba(25, 118, 210, 0.1);
  border-top: 0.5px solid #DBDBDB;
}
.card-container .docs-card .card-footer a {
  color: #6E6E6E;
}

code-example:not(.no-box) {
  background-color: rgba(241, 241, 241, 0.2);
  border: 0.5px solid #DBDBDB;
  color: #444444;
}
code-example[language=shell], code-example[language=sh], code-example[language=bash] {
  background-color: #444444;
}
code-example header {
  background-color: #1E88E5;
  color: #FAFAFA;
}

code-tabs .code-tab-group .mat-tab-label:hover {
  background: rgba(0, 0, 0, 0.04);
}

code-example.avoid header,
code-example.avoidFile header {
  border: 2px solid #DD0031;
  background: #DD0031;
}

code-example.avoid,
code-example.avoidFile,
code-tabs.avoid mat-tab-body,
code-tabs.avoidFile mat-tab-body {
  border: 0.5px solid #DD0031;
}

aio-code pre.prettyprint code ol.linenums {
  color: #aeaeae;
}
aio-code pre.prettyprint code ol.linenums li::marker {
  color: #aeaeae;
}
aio-code pre.prettyprint code .code-missing {
  color: #C3002F;
}
aio-code pre.prettyprint.lang-sh .copy-button, aio-code pre.prettyprint.lang-bash .copy-button {
  color: #DBDBDB;
}
aio-code pre.prettyprint.lang-sh .copy-button:hover, aio-code pre.prettyprint.lang-bash .copy-button:hover {
  color: #F2F2F2;
}
aio-code .copy-button {
  color: #1669bb;
}

.sidenav-content code a,
.sidenav-content a > code {
  color: #1669bb;
}
.sidenav-content :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(pre) > code {
  background-color: rgba(219, 219, 219, 0.3);
}
.sidenav-content :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(pre):not(a) > code {
  color: #444444;
}

/* PRETTY PRINTING STYLES for prettify.js. */
/* The following class|color styles are derived from https://github.com/google/code-prettify/blob/master/src/prettify.css*/
/* SPAN elements with the classes below are added by prettyprint. */
.pln {
  color: #000;
} /* plain text */
@media screen {
  .str {
    color: #800;
  } /* string content */
  .kwd {
    color: #00f;
  } /* a keyword */
  .com {
    color: #060;
  } /* a comment */
  .typ {
    color: red;
  } /* a type name */
  .lit {
    color: #08c;
  } /* a literal value */
  /* punctuation, lisp open bracket, lisp close bracket */
  .pun,
  .opn,
  .clo {
    color: #660;
  }
  .tag {
    color: #008;
  } /* a markup tag name */
  .atn {
    color: #606;
  } /* a markup attribute name */
  .atv {
    color: #800;
  } /* a markup attribute value */
  .dec,
  .var {
    color: #606;
  } /* a declaration; a variable name */
  .fun {
    color: red;
  } /* a function name */
}
/* Use higher contrast and text-weight for printable form. */
@media print, projection {
  .str {
    color: #060;
  }
  .kwd {
    color: #006;
    font-weight: bold;
  }
  .com {
    color: #600;
    font-style: italic;
  }
  .typ {
    color: #404;
    font-weight: bold;
  }
  .lit {
    color: #044;
  }
  .pun,
  .opn,
  .clo {
    color: #440;
  }
  .tag {
    color: #006;
    font-weight: bold;
  }
  .atn {
    color: #404;
  }
  .atv {
    color: #060;
  }
}
/* SHELL / TERMINAL CODE BLOCKS */
code-example[language=shell] .pnk,
code-example[language=shell] .blk,
code-example[language=shell] .pln,
code-example[language=shell] .otl,
code-example[language=shell] .kwd,
code-example[language=shell] .typ,
code-example[language=shell] .tag,
code-example[language=shell] .str,
code-example[language=shell] .atv,
code-example[language=shell] .atn,
code-example[language=shell] .com,
code-example[language=shell] .lit,
code-example[language=shell] .pun,
code-example[language=shell] .dec, code-example[language=sh] .pnk,
code-example[language=sh] .blk,
code-example[language=sh] .pln,
code-example[language=sh] .otl,
code-example[language=sh] .kwd,
code-example[language=sh] .typ,
code-example[language=sh] .tag,
code-example[language=sh] .str,
code-example[language=sh] .atv,
code-example[language=sh] .atn,
code-example[language=sh] .com,
code-example[language=sh] .lit,
code-example[language=sh] .pun,
code-example[language=sh] .dec, code-example[language=bash] .pnk,
code-example[language=bash] .blk,
code-example[language=bash] .pln,
code-example[language=bash] .otl,
code-example[language=bash] .kwd,
code-example[language=bash] .typ,
code-example[language=bash] .tag,
code-example[language=bash] .str,
code-example[language=bash] .atv,
code-example[language=bash] .atn,
code-example[language=bash] .com,
code-example[language=bash] .lit,
code-example[language=bash] .pun,
code-example[language=bash] .dec {
  color: #17ff0b;
}
code-example[language=shell] .copy-button, code-example[language=sh] .copy-button, code-example[language=bash] .copy-button {
  color: #B0BEC5;
}

aio-contributor .contributor-card {
  background: #FAFAFA;
  box-shadow: 0px 1px 6px 1px #6E6E6E;
}
aio-contributor .contributor-image-wrapper {
  background-color: #333333;
}

aio-cookies-popup .cookies-popup {
  background: #252525;
  color: rgba(255, 255, 255, 0.7);
}
aio-cookies-popup .cookies-popup .actions [mat-button] {
  color: #7fc9ff;
}
aio-cookies-popup .cookies-popup .actions [mat-button] .mat-button-focus-overlay {
  background: #FFFFFF;
}

aio-shell.mode-archive .mat-toolbar.mat-primary, aio-shell.mode-archive footer {
  background: linear-gradient(145deg, #263238, #78909C);
}
aio-shell.mode-archive .vertical-menu-item.selected, aio-shell.mode-archive .vertical-menu-item:hover {
  color: #263238;
}
aio-shell.mode-archive .toc-inner ul.toc-list li.active a {
  color: #263238;
}
aio-shell.mode-archive .toc-inner ul.toc-list li.active a:before {
  background-color: #263238;
}
aio-shell.mode-archive .toc-inner ul.toc-list li:hover a {
  color: #263238;
}

aio-shell.mode-next .mat-toolbar.mat-primary, aio-shell.mode-next footer {
  background: linear-gradient(145deg, #DD0031, #C3002F);
}
aio-shell.mode-next .vertical-menu-item.selected, aio-shell.mode-next .vertical-menu-item:hover {
  color: #DD0031;
}
aio-shell.mode-next .toc-inner ul.toc-list li.active a {
  color: #DD0031;
}
aio-shell.mode-next .toc-inner ul.toc-list li.active a:before {
  background-color: #DD0031;
}
aio-shell.mode-next .toc-inner ul.toc-list li:hover a {
  color: #DD0031;
}

aio-shell.mode-rc .mat-toolbar.mat-primary, aio-shell.mode-rc footer {
  background: linear-gradient(145deg, #DDA302, #C3A300);
}
aio-shell.mode-rc .vertical-menu-item.selected, aio-shell.mode-rc .vertical-menu-item:hover {
  color: #DDA302;
}
aio-shell.mode-rc .toc-inner ul.toc-list li.active a {
  color: #DDA302;
}
aio-shell.mode-rc .toc-inner ul.toc-list li.active a:before {
  background-color: #DDA302;
}
aio-shell.mode-rc .toc-inner ul.toc-list li:hover a {
  color: #DDA302;
}

details {
  box-shadow: 0 1px 4px 0 rgba(10, 16, 20, 0.37);
}

.error-list li .symbol.runtime {
  background: #4CAF50;
}
.error-list li .symbol.compiler {
  background: #2196F3;
}
.error-list li a {
  color: #546E7A;
}
.error-list li a:hover {
  background: #ECEFF1;
  color: #2196F3;
}

.filetree {
  background: #FFFFFF;
  border: 4px solid #DBDBDB;
}
.filetree .file {
  color: #444444;
}
.filetree .children .file:before {
  border-color: #DBDBDB;
}

.reviewed {
  color: #5e5e5e;
}

.sidenav-content h1 .header-link,
.sidenav-content h2 .header-link,
.sidenav-content h3 .header-link,
.sidenav-content h4 .header-link,
.sidenav-content h5 .header-link,
.sidenav-content h6 .header-link {
  color: #6E6E6E;
}

hr {
  background: #DBDBDB;
}

.content .lightbox {
  border: 1px solid #DBDBDB;
  background-color: #EBEBEB;
}
.content .lightbox img {
  background-color: #FFFFFF;
}

.api-header label {
  color: #FFFFFF;
}

label.api-status-label {
  color: #FFFFFF;
  background-color: #6E6E6E;
}
label.api-status-label.deprecated, label.api-status-label.security, label.api-status-label.impure-pipe, label.api-status-label.dev-preview, label.api-status-label.experimental {
  background-color: #DD0031;
}
label.api-status-label a {
  color: inherit;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
}
label.api-type-label {
  color: #FFFFFF;
  background-color: #1E88E5;
}
label.api-type-label.all {
  background: #FFFFFF;
}
label.api-type-label.class {
  background: #2196F3;
}
label.api-type-label.const {
  background: #6E6E6E;
}
label.api-type-label.decorator {
  background: #1565C0;
}
label.api-type-label.directive {
  background: #D81B60;
}
label.api-type-label.enum {
  background: #FFA000;
}
label.api-type-label.function {
  background: #4CAF50;
}
label.api-type-label.interface {
  background: #009688;
}
label.api-type-label.let {
  background: #6E6E6E;
}
label.api-type-label.ngmodule {
  background: #940;
}
label.api-type-label.package {
  background: #8E24AA;
}
label.api-type-label.pipe {
  background: #546E7A;
}
label.api-type-label.element {
  background: #AA3000;
}
label.api-type-label.block {
  background: #2E7D32;
}
label.api-type-label.type-alias {
  background: #7CB342;
}
label.api-type-label.var {
  background: #6E6E6E;
}
label.page-label {
  color: #FFFFFF;
  background-color: #ECEFF1;
  color: #6E6E6E;
}
label.property-type-label {
  color: #FFFFFF;
  background-color: #444444;
  color: #FFFFFF;
}

aio-notification {
  background: #444444;
}
aio-notification .close-button {
  background: #444444;
}
aio-notification .content {
  background: #444444;
}
aio-notification .content .action-button {
  background: #DD0031;
}

.presskit-container .presskit-section:not(:first-child) {
  border-top: 1px solid #DBDBDB;
}
.presskit-container .presskit-section .presskit-icon-group .presskit-icon-item .presskit-image-container .transparent-img {
  background-color: #FFFFFF;
}
.presskit-container .presskit-section .presskit-icon-group .presskit-icon-item .presskit-image-container .transparent-img-inverse {
  background-color: #333333;
}

aio-resource-list .subcategory-title {
  background-color: #ECEFF1;
}
aio-resource-list .resource-row-link {
  background-color: #FFFFFF;
}
aio-resource-list .resource-row-link:hover {
  border-color: rgba(25, 118, 210, 0.5);
  box-shadow: 0 8px 8px rgba(1, 131, 163, 0.24), 0 0 8px rgba(1, 67, 163, 0.12), 0 6px 18px rgba(43, 133, 231, 0.12);
}

.page-guide-roadmap .completed-details {
  border-top: 1px solid #DBDBDB;
}

aio-search-results .search-results {
  background-color: #444444;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}
aio-search-results .search-results .search-area .search-section-header {
  color: #FFFFFF;
}
aio-search-results .search-results .search-area ul .search-result-item {
  color: #FAFAFA;
}
aio-search-results .search-results .search-area ul .search-result-item:hover {
  color: #FFFFFF;
}
aio-search-results .search-results .no-results {
  color: #FFFFFF;
}
aio-search-results .search-results a {
  color: rgba(0, 0, 0, 0.87);
}
aio-search-results .search-results .close-button {
  color: #FAFAFA;
}
aio-search-results.embedded .search-results .search-area .search-section-header {
  color: #444444;
}
aio-search-results.embedded .search-results .search-area .search-result-item {
  color: #5e5e5e;
}
aio-search-results.embedded .search-results .search-area .search-result-item:hover {
  color: #1E88E5;
}
aio-search-results.embedded .search-results .no-results {
  color: rgba(0, 0, 0, 0.87);
}
aio-search-results.embedded .search-results a {
  color: #1976D2;
}

aio-select .form-select-button {
  background: #FFFFFF;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  border: 1px solid #FFFFFF;
  color: #546E7A;
}
aio-select .form-select-button:focus {
  border: 1px solid #42A5F5;
  box-shadow: 0 2px 2px rgba(66, 165, 245, 0.24), 0 0 2px rgba(66, 165, 245, 0.12);
}
aio-select .form-select-button.disabled {
  color: lightgrey;
}
aio-select .form-select-dropdown {
  background: #fafafa;
  box-shadow: 0 16px 16px rgba(10, 16, 20, 0.24), 0 0 16px rgba(10, 16, 20, 0.12);
}
aio-select .form-select-dropdown li:hover, aio-select .form-select-dropdown li.current {
  background-color: #ECEFF1;
  color: f(false, #78909C, #607D8B);
}
aio-select .form-select-dropdown li.selected {
  background-color: #CFD8DC;
}

table {
  min-width: 100%;
  box-shadow: 0 2px 2px rgba(110, 110, 110, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  background-color: #FFFFFF;
}
table thead > tr > th {
  background: rgba(219, 219, 219, 0.2);
  border-bottom: 1px solid #DBDBDB;
  color: #444444;
}
table tbody > tr > th,
table tbody > tr > td {
  border-bottom: 1px solid #DBDBDB;
}
@media (max-width: 480px) {
  table tbody > tr > td tr td:first-child {
    background-color: #DBDBDB;
  }
}
table tbody > tr > th {
  background: rgba(219, 219, 219, 0.2);
}
table tbody > tr > th:not(:last-child) {
  border-right: 1px solid #DBDBDB;
}
@media (max-width: 480px) {
  table tbody > tr:last-child td {
    border-bottom: 1px solid #DBDBDB;
  }
}

aio-toc .toc-inner .toc-heading.secondary:hover {
  color: #1E88E5;
}
aio-toc .toc-inner button.toc-heading.embedded:focus, aio-toc .toc-inner button.toc-more-items.embedded:focus {
  background: #DBDBDB;
  color: #6E6E6E;
}
aio-toc .toc-inner button.toc-heading:hover:not(.embedded) {
  color: #1E88E5;
}
aio-toc .toc-inner button.toc-more-items {
  color: #6E6E6E;
}
aio-toc .toc-inner button.toc-more-items:hover {
  color: #1E88E5;
}
aio-toc .toc-inner ul.toc-list li.h1:after {
  background: #DBDBDB;
}
aio-toc .toc-inner ul.toc-list li a {
  color: #5e5e5e;
}
aio-toc .toc-inner ul.toc-list li:hover * {
  color: #1E88E5;
}
aio-toc .toc-inner ul.toc-list li.active * {
  color: #1976D2;
}
aio-toc .toc-inner ul.toc-list li.active a:before {
  background: #1976D2;
}
aio-toc .toc-inner ul.toc-list:not(.embedded) li:before {
  border-left-color: #DBDBDB;
}
aio-toc .toc-inner ul.toc-list:not(.embedded) li:not(.active):hover a:before {
  background: #DBDBDB;
}
aio-toc.embedded .toc-inner .toc-heading.secondary {
  color: rgba(0, 0, 0, 0.87);
}